/*=============================================================================================================================================*/
/*================                                                                                                             ================*/
/*================              Ceci est le CSS de base du site.                                                               ================*/
/*================              Ne pas toucher à ce fichier mais faire les surcharges dans le fichier master.css,              ================*/
/*================              situé dans ce même répertoire. Tout contrevenant sera immédiatement transformé en oeuf dur.    ================*/
/*================                                                                                                             ================*/
/*=============================================================================================================================================*/


/*========================================================================================*/
/*================                  TOOLS                                 ================*/
/*========================================================================================*/

/* Layout tools */

/* floats */
.float-left { float:left; }
.float-right { float:right; }
.clear,
.float-left + *:not(.float-left):not(.float-right),
.float-right + *:not(.float-left):not(.float-right) {
    clear: both;
    display: block;
}
/* use .mbp to stack under the Main Break Point */
@media ( max-width: $mbp) {
    .float-left.mbp,
    .float-right.mbp {
        float: none;
    }
}

/* TODO: adding flex tools */

/* Hidden tools */

/* Hide only visually, but have it available for screenreaders: h5bp.com/v */
.visuallyhidden{border:0;clip:rect(0000);height:1px;overflow:hidden;position:absolute;width:1px;margin:-1px;padding:0;}
/* Extends the .visuallyhidden class to allow the element to be focusable when navigated to via the keyboard: h5bp.com/p */
.visuallyhidden.focusable:active,.visuallyhidden.focusable:focus{clip:auto;height:auto;overflow:visible;position:static;width:auto;margin:0;}

/* Text cut tools */

/* Automatic text cut to prevent text superposition : http://rocssti.nicolas-hoffmann.net/ */
.cut { word-wrap: break-word; -webkit-hyphens: auto; -moz-hyphens: auto; -ms-hyphens: auto; -o-hyphens: auto; hyphens: auto; }
.nocut { word-wrap: normal; -webkit-hyphens: none; -moz-hyphens: none; -ms-hyphens: none; -o-hyphens: none; hyphens: none; }
/* add ... if too long text-line, must specify a width to this container */
.ellipsis { white-space: nowrap; overflow: hidden; -o-text-overflow: ellipsis;  text-overflow: ellipsis; }



/*========================================================================================*/
/*================                  GENERAL LAYOUT                        ================*/
/*========================================================================================*/
html {
    font-size: 16px;
}

body {
    font-size : 1rem;
}

.container.container-main {
    min-height: 500px;
    padding: 20px 40px;
}

a:link,
a:visited,
a:hover,
a:active {
    outline: none;
}
/* keybord accessibility : keeps original focus outline */
a:focus,
button:focus {
    outline: thin dotted;
    outline-offset: 0;
}


/* links visual accessibility : internal p or li text links */
p a,
.description li a{
    text-decoration: underline;
}

/* Using html selector vs universal selector(*) for more flexibility */
/* more infos here : https://css-tricks.com/inheriting-box-sizing-probably-slightly-better-best-practice/ */
html {
    box-sizing: border-box;
}
*,
*:before,
*:after {
    box-sizing: inherit;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    font-family: $title-font;
    font-weight: 300;
    line-height: none;
}

h1 {
    margin-bottom: 15px;
}

ul {
    margin-bottom: 0;
}

.nav > li {
    display: inline-block;

    margin-left: 20px;

    list-style-position: inside;
    list-style-type: none;
}
.nav > li:first-child,
li:first-child {
    margin-left: 0;
}

.nav > li > a {
    padding: 0;
}

#content {
    margin: 25px 0;
}

.bold {
    font-weight: bold;
}

.radio input[type='radio'],
.checkbox input[type='checkbox'] {
    margin-left: 0;
}


/* flex sticky footer
TODO : check on mobiles
*/


/**
* 1. Avoid the IE 10-11 `min-height` bug.
* 2. Set `flex-shrink` to `0` to prevent some browsers from
*    letting these items shrink to smaller than their content's default
*    minimum size. See http://bit.ly/1Mn35US for details.
* 3. Use `%` instead of `vh` since `vh` is buggy in older mobile Safari.
*/

.page-body {
    display: flex;
    flex-direction: column;
    height: 100vh; /* 1, 3 */
}

.page-header,
.sticky-footer {
    flex: none; /* 2 */
}

.page-content {
    flex: 1 0 auto; /* 2 */
    padding: 0;
    width: 100%;
}


/*========================================================================+===============*/
/*================                      HEADER                           ================*/
/*========================================================================================*/


/* relative #accountMenu */
.container.container-header {
    position: relative;
}
@if ($logo-position == "left") {
    .logo-wrapper {
        float: left;
        margin-left: 0;
    }
}

@if ($logo-position == "right") {
    .container.container-header {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
    }
    .logo-wrapper {
        float: none;
        margin-left: 0;
        -webkit-box-ordinal-group: 3;
        -moz-box-ordinal-group: 3;
        -ms-flex-order: 3; 
        -webkit-order: 3; 
        order: 3;
    }
}
@if ($logo-position == "center") {
    .container.container-header {
        display: flex;
        flex-direction: column;

    }
    .nav-wrapper {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        -webkit-box-ordinal-group: 1;
        -moz-box-ordinal-group: 1;
        -ms-flex-order: 1; 
        -webkit-order: 1; 
        order: 1;
    }
    .logo-wrapper {
        float: none;
        flex: 0 0 auto;

        -webkit-box-ordinal-group: 2;
        -moz-box-ordinal-group: 2;
        -ms-flex-order: 2; 
        -webkit-order: 2; 
        order: 2;
    }
    /* iPad classic */
    @media (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {   
        .logo-wrapper {
            left: 42%;
        }

    }

    /* IE11 + Safari > 7 */
    @media screen { 
        @media (min-width: 0px) {
            .logo-wrapper {
                left: 42%;
            }
        }
    }
}


.nav-wrapper {
    padding-bottom: 40px;
    /*overflow: hidden;*/

    text-align: right;
}

.siteLogo {
    display: block;

    width: 280px;
    min-height: 95px;
    margin-top: 10px;

    background-size: contain;
}

.siteLogo:hover {
    transition: all 200ms linear;

    opacity: .6;
}

header li a {
    transition: $link-transition;

    opacity: 1;
}

header .nav a:hover,
header .nav a:focus {
    transition: $link-transition;
    text-decoration: none;

    opacity: .5;
    background-color: transparent;
}

header article {
    float: right;

    margin-left: 20px;
}

header article:first-child {
    margin-left: 0;
}

#showcase {
    min-height: 474px;

    background-size: cover;
    box-shadow: 0 20px 15px -20px #a2a2a2 inset;
}

/* Skip menu accessibility tool */
.skip-menu {
    max-width: 970px;
    position: relative;
    margin: 0 auto;
}
.skip-menu a {
    left: -7000px;
    padding: 0;
    position: absolute;
    text-decoration: none;
    z-index: 1000;
    display: inline-block;
    background-color: $header-bg;
}
.skip-menu a:focus {
    left: 0;
    top: 0.2em;
}


/* Select2 background */
.formitem input, .formitem textarea, .formitem select, .formitem .select2-choice {
    background-color: #fff;
}

/*language selector*/
/*menu*/
/* CHANGED No more absolute position */
/* trying to use inline-block elements vs absolute or floats */
.languageModule {
    position: relative;
    top: auto;
    right: auto;
    margin-top: 0;
}
.languageModule .languageFlag {
    margin-top: -4px;
}
.languageModule img {
    vertical-align: middle;
}
header .languageName {
    vertical-align: middle;
}


/*account menu*/
#accountMenu {
    position: relative;
}
#accountMenu .nav {
    padding-top: 3px;
}

/*logged infos*/
.dropdown-toggle:after {
    border: none;
}
.extension_Login .form-vertical.login-form.logout {
    margin-right: 20px;

    .dropdown-menu {
        top: 80%;
    }

    i {
        display: inline-block;
    }

    .gravatar-user-wrapper {
        display: inline-block;
    }

    .fa.fa-angle-down {
        padding-left: 10px;
    }
    .fa.fa-stack {
        line-height: 1.8;
    }
    .dropdown-menu > li > a {
        padding: 3px 10px;
        display: block;
    }
}

header .extension_Login .nav > li > a {
    padding-top: 2px;
}

#accountMenu,
#logged-infos {
    display: inline-block;

    vertical-align: text-top;
}
.nav-wrapper .extension_Language {
    display: inline-block;

    vertical-align: text-top;
}
#logged-infos .nav > li {
    z-index: 10000;
}

/*========================================================================+===============*/
/*================                      FOOTER                            ================*/
/*========================================================================================*/
footer {
    padding: 40px;
}

footer ul.nav {
    margin-bottom: 0;
}

footer ul.nav a:focus,
footer ul.nav a:hover {
    transition: $link-transition;
    text-decoration: none;

    opacity: 1;
    background-color: transparent;
}

footer li {
    line-height: 30px;

    width: 33%;
    margin-left: 0 !important;
    padding-left: 60px;
}
@media (min-width: $mbp-up){
    .container-footer {
        display: flex;
        justify-content: space-between;
    }
    footer .footer-navigation {
        flex: 1 0 auto;
    }
    footer .social-logos {
        text-align: right;
        display: inline-block;
    }
    @if ($footer-logo == false){
        .footer-logo{
            display: none;
        }
    }
}


#pdf-policy {
    width: 100%;
    min-height: 650px;
}




/*================================================================================================*/
/*================                  INFO / SUCCESS / ERROR                        ================*/
/*================================================================================================*/
.ticket-info {
    color: #635c56;
    border: 1px solid #d3d871;
    background-color: #f8f5d2;
}

.ticket-success {
    color: #635c56;
    border: 1px solid #eaeed7;
    background-color: #e5efcc;
}

.ticket-error {
    background-color: #fed6d6;
}

.ticket-success,
.ticket-info,
.ticket-error {
    font-size: 13px;

    float: left;

    margin: 15px 0;
    padding: 7px;
}

.ticket-success span,
.ticket-info span,
.ticket-error span {
    font-weight: bold;
}

#error {
    margin: 20px 20px 30px 20px;
    padding: 30px;

    background-color: #fbe5e5;
}

/*===============================================================================*/
/*================                  TABLE                        ================*/
/*======================================================+========================*/
.table-holder {
    overflow: auto;
}

.table-holder .table .align-left {
    text-align: left;
}

.table-holder .table .align-right {
    text-align: right;
}

.table-holder .table .align-center {
    text-align: center;
}

.table-holder table {
    width: 100%;
}

.table-holder table.table {
    margin-bottom: 0 !important;
}

.table-holder,
.table-holder table td {
    vertical-align: middle;

    color: #444;
}

.table-holder table td,
.table-holder table th {
    padding: 8px;

    empty-cells: show;
}

.table-holder table th {
    font-size: 13px;

    padding: 8px;

    vertical-align: middle;

    color: #4d4d4d;
}

.table-holder table thead tr:first-child {
    background: linear-gradient(to bottom, #fff 0%, #f1f1f1 100%) repeat scroll 0 0 rgba(0, 0, 0, 0);
}

.table-holder table th.sorting,
.table-holder table th.sorting_asc,
.table-holder table th.sorting_desc {
    padding: 8px 19px 8px 8px;
}

.table-holder .form-buttons {
    margin-top: 30px;
}

.table-holder table th .sort i {
    line-height: 20px;

    float: right;
}

.table-holder table th .sort:hover,
.table-holder table th .sort:focus {
    text-decoration: none;
}

.table-holder table .th-checkbox {
    width: 0;
}

.table-holder table td label.checkbox {
    width: 100%;
}

.table-holder table td a {
    display: block;

    width: 100%;
}

.table-holder table td a:hover,
.table-holder table td a:focus {
    text-decoration: none;
}

.table-holder table.clickable td {
    cursor: pointer;
}

.table-holder table.clickable tr.active td,
.table-holder table.clickable tr.active td:hover {
    color: #fff;
    background-color: #62737b !important;
}

table.table {
    margin: 0;
}

.table-holder table td.details-holder,
.table-holder table th.details-holder {
    display: none;
}

.table-holder th.large {
    min-width: 250px;
    max-width: 250px;
}

.table-holder th.medium {
    min-width: 100px;
    max-width: 100px;
}

.table-holder th.small {
    min-width: 50px;
    max-width: 50px;
}

.table-holder .dataTable .details {
    padding: 0;

    background-color: #fff;
}

.table-holder div.innerDetails {
    display: none;

    padding: 10px;

    background-color: #fff;
}

.table-holder .dataTable .details tr td {
    padding: 20px 20px 20px 25px;

    color: #777;
    border-left: 20px solid #f1f1f1;
    background-color: #fff;
}

.table-holder table thead input[type='text'] {
    display: block;

    -moz-box-sizing: border-box;
    box-sizing: border-box;
    width: 100%;
    min-width: 200px;
    min-height: 28px;
    margin: 0;
}

.table-holder table thead input.search_init {
    min-width: 0;
}

.dataTables_filter {
    display: none;
}

.table-striped tbody > tr:nth-child(2n+1) > td,
.table-striped tbody > tr:nth-child(2n+1) > th {
    background-color: #fcfcfc;
}

.table-hover tbody tr:hover > td,
.table-hover tbody tr:hover > th {
    background-color: #f5f5f5;
}

.table-holder table thead td {
    background-color: #fff;
}

.table-holder .show-details i {
    color: #aaa;
}


/*===============================================================================*/
/*================                  FORMS                        ================*/
/*===============================================================================*/

.formitem label,
.extension_Form .top .formitem label {
    margin-top: 1rem;
    margin-bottom: .1rem;
}
.formitem .required {
    font-size: 1rem;
    vertical-align: inherit;
}

label.m-wrap,
input.m-wrap,
button.m-wrap,
select.m-wrap,
textarea.m-wrap {
    font-size: 14px;
    font-weight: normal;
    line-height: 20px;
}

input.m-wrap,
button.m-wrap,
select.m-wrap,
textarea.m-wrap {
    font-family: 'Segoe UI','Helvetica Neue',Helvetica,Arial,sans-serif;
}

label.m-wrap {
    display: block;

    margin-bottom: 5px;
}

.extension_Form .left label,
.extension_Form .left input,
.extension_Form .left .multi-date,
.left .control .select2-container.full {
    margin: 0;
}

.extension_Form .left .multi-date div.select2-container {
    width: 18%!important;
}

.uneditable-input,
textarea.m-wrap,
input.m-wrap[type='text'],
input.m-wrap[type='password'],
input.m-wrap[type='datetime'],
input.m-wrap[type='datetime-local'],
input.m-wrap[type='date'],
input.m-wrap[type='month'],
input.m-wrap[type='time'],
input.m-wrap[type='week'],
input.m-wrap[type='number'],
input.m-wrap[type='email'],
input.m-wrap[type='url'],
input.m-wrap[type='search'],
input.m-wrap[type='tel'],
input.m-wrap[type='color'] {
    font-size: 14px;
    font-weight: normal;
    line-height: 20px;
    padding: 6px;

    vertical-align: top;

    color: #333;
    outline: 0 none;
    box-shadow: none;

    filter: none;
}

input.m-wrap {
    border: 1px solid #e5e5e5;
}

.toolbox-content input.m-wrap {
    color: #a0a0a0;
    border: none;
}

textarea.m-wrap {
    height: auto;
}

.uneditable-input.focus,
textarea.m-wrap:focus,
select.m-wrap:focus,
input.m-wrap[type='text']:focus,
input.m-wrap[type='password']:focus,
input.m-wrap[type='datetime']:focus,
input.m-wrap[type='datetime-local']:focus,
input.m-wrap[type='date']:focus,
input.m-wrap[type='month']:focus,
input.m-wrap[type='time']:focus,
input.m-wrap[type='week']:focus,
input.m-wrap[type='number']:focus,
input.m-wrap[type='email']:focus,
input.m-wrap[type='url']:focus,
input.m-wrap[type='search']:focus,
input.m-wrap[type='tel']:focus,
input.m-wrap[type='color']:focus,
.m-uneditable-input:focus {
    border-color: #999;
    outline: 0 none;
    box-shadow: none;
}

input[disabled],
select[disabled],
textarea[disabled] {
    cursor: not-allowed;

    background-color: #f4f4f4;
}

input[readonly],
select[readonly],
textarea[readonly] {
    cursor: not-allowed;

    background-color: #f9f9f9;
}

input[type='radio'][disabled],
input[type='checkbox'][disabled],
input[type='radio'][readonly],
input[type='checkbox'][readonly] {
    background-color: rgba(0, 0, 0, 0);
}

input.m-wrap[type='radio'],
input.m-wrap[type='checkbox'] {
    line-height: normal;

    -moz-box-sizing: border-box;
    margin: 4px 0 0;

    cursor: pointer;
}

select.m-wrap,
input.m-wrap[type='file'] {
    line-height: 30px;

    min-height: 34px;
}

select.m-wrap {
    width: 220px;

    border: 1px solid #e5e5e5;
    outline: medium none;
    background-color: #fff;
    background-image: none;

    filter: none;
}

select.m-wrap[multiple],
select.m-wrap[size] {
    height: auto;
}

select.m-wrap:focus,
input.m-wrap[type='file']:focus,
input.m-wrap[type='radio']:focus,
input.m-wrap[type='checkbox']:focus {
    outline: thin dotted #333;
    outline-offset: -2px;
    box-shadow: none;
}

input.m-wrap[class*='span'] {
    float: none;

    height: 34px;
    margin-left: 0;
}

select.m-wrap[class*='span'],
textarea.m-wrap[class*='span'] {
    float: none;

    margin-left: 0;
}

.input-prepend,
.input-append {
    display: inline-block;

    border: 0 none;
}

.input-append .add-on,
.input-prepend .add-on {
    font-size: 14px;
    font-weight: normal;
    line-height: 24px;

    display: inline-block;

    width: auto;
    min-width: 16px;
    min-height: 24px;
    padding: 4px 5px;

    text-align: center;

    border: 1px solid #e5e5e5;
    background-color: #e5e5e5;
    text-shadow: 0 1px 0 #fff;
}

.input-append .add-on > .halflings-icon,
.input-prepend .add-on > i,
.input-prepend .add-on > [class^='icon-'] {
    margin-top: 5px;
    margin-left: 3px;
}

.input-append .add-on > .halflings-icon .input-append .add-on > i,
.input-append .add-on > [class^='icon-'] {
    margin-top: 5px;
    margin-left: 0;
}

.input-append .m-wrap,
.input-append .m-wrap:focus {
    margin-right: 1px;
}

.input-prepend input,
.input-prepend input:focus {
    margin-left: 1px;

    border-left: 0 none;
}

.input-append input[class*='span'],
.input-prepend input[class*='span'] {
    display: inline-block;
}

.m-wrap.xsmall {
    width: 50px;
}

.m-wrap.small {
    width: 120px;
}

.m-wrap.medium {
    width: 206px;
}

.m-wrap.large {
    width: 320px;
}

.m-wrap.huge {
    font-size: 24px;
    line-height: 36px;

    width: 480px;
    min-height: 36px;
    padding: 22px 8px;
}

select.m-wrap.xsmall {
    width: 64px;
}

select.m-wrap.small {
    width: 134px;
}

select.m-wrap.medium {
    width: 220px;
}

select.m-wrap.large {
    width: 334px;
}

textarea,
input[type='text'],
input[type='password'],
input[type='datetime'],
input[type='datetime-local'],
input[type='date'],
input[type='month'],
input[type='time'],
input[type='week'],
input[type='number'],
input[type='email'],
input[type='url'],
input[type='search'],
input[type='tel'],
input[type='color'],
.uneditable-input,
.login-form input.m-wrap[type='text'],
.login-form input.m-wrap[type='password'],
.forget-form input.m-wrap[type='text'] {
    /*height: 35px;*/
    line-height: 35px;

    padding: 0 5px !important;

    transition: $input-transition;

    border: 1px solid #ccc;
    background-color: #fff;
    box-shadow: none;
}

input:focus:invalid:focus,
textarea:focus:invalid:focus,
select:focus:invalid:focus {
    border-color: #666;
    box-shadow: none;
}

.select2-container .select2-choice span {
    padding: 0 10px 0 5px;
}





/*===============================================================================*/
/*================                  LOGIN                        ================*/
/*===============================================================================*/
.login-form #avatar,
.login-form img,
.login-form .input-icon i.icon-user,
.login-form .input-icon i.icon-lock,
.forget-form .input-icon i.icon-envelope {
    display: none;
}

.login-form label,
.forget-form label {
    font-family: $base-font;
    font-size: 14px;
    font-weight: normal;
    line-height: 20px;

    display: block;

    margin-bottom: 5px;
}

.login-form input.m-wrap[type='text'],
.login-form input.m-wrap[type='password'],
.forget-form input.m-wrap[type='text'] {
    font-family: $base-font;
    font-size: 13px;
    line-height: 35px;

    width: 100%;
    min-height: 35px;
    padding: 0 5px;

    border: 1px solid #d0d0d0;
    box-shadow: none;
}

.login-form .btn.btn-save:after,
.forget-form .btn.btn-delete:after,
.forget-form .btn.btn-save:after {
    clear: both;
}

.login-form,
.forget-form {
    margin: 0;
    padding: 0;

    border: medium none;
    background-color: transparent;
}

/*.login-form .btn.btn-save,
.forget-form .btn.btn-delete,
.forget-form .btn.btn-save {
font-family: 'Open Sans';

margin-top: 15px;
margin-left: 15px;
padding: 10px 30px;

cursor: pointer;
text-transform: uppercase;

color: #fff;
border: medium none;
background: none;
box-shadow: none;
text-shadow: none;
}

.login-form .btn.btn-save:hover,
.forget-form .btn.btn-delete:hover,
.forget-form .btn.btn-save:hover {
text-decoration: none;

color: #fff;
}*/

.login-form .form-actions,
.forget-form .form-actions {
    clear: both;

    margin: 0;
    padding: 0;

    border: none;
    background-color: transparent;
}

#back-btn.pull-right {
    float: left !important;

    margin: 30px 0 !important;
}

.forget-form {
    display: none;
}

.login-form .checkbox {
    padding-left: 0;
}

.extension_Login i {
    display: none;
}

.extension_Login div.checker {
    margin-top: 0;
}

.extension_Login .remember {
    float: left;
}

.extension_Login .forget-password {
    line-height: 20px;

    float: right;
}

.extension_Login .forget-password a {
    font-size: 14px;

    color: #3a3936;
}

.extension_Login .forget-password a:hover {
    opacity: .8;
}

.extension_Login .btn-save {
    margin-top: 30px !important;
}


/*===============================================================================================*/
/*================                          TUNNEL                               ================*/
/*===============================================================================================*/

.default .tunnel {
    padding: 10px;
}
.hidden-xs.step.active {
    display: block !important;
}


/*===============================================================================================*/
/*================                  FAMILLES DE PRODUITS                         ================*/
/*===============================================================================================*/


/*.extension_Familly {
.view.default {
.product {
border-bottom: none !important;
box-shadow: none !important;
img {
width: 100%;
}
.btn.btn-primary {
width: 100%;
height: auto;
}
}
}
}*/

.product-title {
    margin-top: 0;
}

.family-title {
    font-family: $title-font;
    margin-left: 15px;
    margin-right: 15px;
}

.familyModule h2 {
    font-size: 30px;

    margin-top: 0;
    margin-bottom: 40px;
}

.familyModule .product img {
    float: none;
}

.familyModule .product .title {
    line-height: normal;

    margin: 40px 0 10px 0;
}

.familyModule .product .title span {
    font-family: 'Open Sans',Arial,Helvetica,sans-serif;
    font-size: 17.5px;
    font-weight: 300;
}

.familyModule .product .description {
    font-size: 14px;

    min-height: 70px;
}

.familyModule .product .description p {
    white-space: normal;
}

.familyModule .product.no-border {
    margin-top: 0;
    padding: 20px;

    border-top: none;
}

.familyModule .product.no-border:first-child {
    margin-top: 0;
}

/*.familyModule .product a.btn-buy-now {
width: 100%;

transition: all 200ms linear;

opacity: 1;
background-color: #5b5a54;
}

.familyModule .product a.btn-buy-now:hover {
transition: all 200ms linear;

opacity: .8;
background-color: #5b5a54;
}*/

.extension_Familly {
    padding: 0 !important;
}

@media (min-width: $mbp-up) {
    .extension_Familly > div > p {
        padding-left: 15px;
        padding-right: 15px;
    }
}

.extension_Familly form {
    margin: 0;
}

.extension_Text h2 {
    margin: 0;
}

.familyModule .product {
    float: left;

    width: 50%;
}

.extension_Products  .product {
    margin: 0 0 30px;
    padding: 40px;

    border: 1px solid #ddd;
    box-shadow: none;
}
.families .extension_Products .product {
    margin: 0 0 40px;
}
.extension_Products .families h2 {
    display: none;
}

.extension_Products  .product .title {
    font-family: $base-font;
    font-size: 20px;
    font-weight: normal;

    color: #3a3936;
}

.extension_Products  .product.odd,
.product.even {
    background-color: #fff;
}

.extension_Products  .product img {
    float: none;
}

.extension_Products .vertical .product img {
    width: 100%;
    margin: 0 0 40px 0;

    border: none;
}

.extension_Products .vertical .header-price,
.extension_Products .vertical .header-quantity {
    color: #3a3936;
}


/*===============================================================================================*/
/*================                            TUNNEL                             ================*/
/*===============================================================================================*/

.extension_Tunnel .step.complete .number {
    padding-right: 1px;
    padding-left: 0;
}

/*.bottom .tunnel {
.btn-save,
.btn-next,
.btn-ext,
.btn-previous,
.btn-cancel {
font-family: $base-font;

margin-top: 15px;
padding: 10px 30px;

cursor: pointer;
transition: all 200ms linear;
text-transform: uppercase;

border: none;
}

.btn-save:hover,
.btn-next:hover {
transition: all 200ms linear;
text-decoration: none;

opacity: .8;
}
}*/



/*===============================================================================================*/
/*================                          PANIER                               ================*/
/*===============================================================================================*/

.extension_Cart .cart {
    box-shadow: none;
}

.extension_Cart .cart-summary {
    box-shadow: none;
}

.extension_Cart #show-cart {
    display: none;
}

.extension_Cart {
    padding-top: 0;
}

.extension_Cart .cart-summary {
    margin: 0;
    padding: 0;

    border: none;
}

.extension_Cart .cgv a {
    margin-left: 25px;
}

.extension_Cart .cart-summary .products {
    margin: 0 0 30px;
}

.extension_Cart .cart-summary h3 {
    display: none;
}

.extension_Cart .cart-summary .products tr.odd,
.extension_Cart .cart-summary .products tr.even {
    /*background-color: $cart-bg;*/
    border: 1px solid #d0d0d0;
}

.extension_Cart .cart-summary .products thead tr {
    border: 1px solid #d0d0d0;
}

.extension_Cart hr {
    display: none;
}

.extension_Cart .cart-summary .products thead th {
    line-height: 30px !important;

    height: 30px;
    padding: 10px;
}

.cart-summary .ticket-info,
.cart-summary table {
    width: 100%;
}

/* OPTION BOUTON AJOUT AU PANIER AJAX */
.addButton.col-xs-12.col-sm-12,
.addButton.col-xs-12.col-md-12{
    padding-left: 0;
    padding-right: 0;
}
.addCartInformation {
    margin-bottom: 20px;
}
@media (min-width: 768px) {
    .addButton {
        text-align: right;
        margin-bottom: 20px !important;
    }
    .addButton:after {
        content: '';

        display: block;
    }
    .addButton span {
        display: inline-block;
        margin-bottom: 20px;
    }
    .addCartInformation {
        clear: both;
    }
}

/*===============================================================================*/
/*================                  IMAGE                        ================*/
/*===============================================================================*/
.extension_Image img {
    width: 100%;
}

/*==================================================================================================*/
/*================                          CALENDRIER                              ================*/
/*==================================================================================================*/
.extension_Booking .booking .content .day {
    border: none;
    border-top: 1px solid #fff;
    border-left: 1px solid #fff;
}

.extension_Booking .booking .content .week {
    border-right: none;
    border-left: none;
}

.extension_Booking .booking .day-labels {
    margin-bottom: 0;
}

.extension_Booking .booking {
    padding: 0;

    box-shadow: none;
}

.extension_Booking .content {
    margin-right: 1px;
    margin-bottom: 1px;
}

/*============================================================================================================*/
/*================                            PAIEMENT                                        ================*/
/*============================================================================================================*/

.extension_Payment i {
    font-size: 80px;
}

.extension_Payment .content {
    padding-left: 120px;
}

.extension_Payment .content p {
    margin: 0 0 50px 0;
}

/*=============================================================================================================*/
/*================                                ORDER                                        ================*/
/*=============================================================================================================*/

.extension_Order thead tr {
    border: 1px solid #d0d0d0;
}

.extension_Order thead th {
    line-height: 30px;

    padding: 10px;
}

.extension_Order tr {
    line-height: 30px;

    border: 1px solid #d0d0d0;
    background-color: #fff;
}

.extension_Order .order-detail > td {
    padding: 40px;
}

.extension_Order .btn {
    margin-bottom: 3px !important;
}

.extension_Text h4 strong {
    /*font-size: 17.5px;*/
}

.formitem legend {
    color: #3a3936;
}

.extension_Text p {
    margin-top: 20px;
}

.totalExVAT td:last-child,
.taxValue td:last-child,
.totalValue td:last-child {
    padding-right: 26px;
}



/*=============================================================================================================*/
/*================                                TEXT                                        ================*/
/*=============================================================================================================*/

.extension_Text p {
    font-family: $base-font;
    font-size: 14px;

    margin: 0;
}


/*===============================================================================================*/
/*================                  divers                                        ================*/
/*===============================================================================================*/

a.alpha8hover:hover {
    opacity: .8;

    filter: alpha(opacity=80);
}





/*+++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++*/
/*+++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++*/
/*+++++++++                                                                                                                                           +++++++++*/
/*+++++++++                                                         RESPONSIVE                                                                        +++++++++*/
/*+++++++++                                                                                                                                           +++++++++*/
/*+++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++*/
/*+++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++*/


/*================================================================================*/
/*================                   LARGE                        ================*/
/*================================================================================*/

@media (min-width: 1200px) {
    .container {
        max-width: 970px;
    }
}

/*================================================================================*/
/*================                  PHONES                        ================*/
/*================================================================================*/

/*#menuTrigger {
color: $menu-link;
}*/

@media (max-width: $mbp) {
    .container.container-main {
        padding: 20px 15px;
    }

    .btn {
        line-height: 40px;
    }

    /*formulaire d'inscription*/
    .newUser {
        margin-bottom: 40px;
        padding-bottom: 40px;

        border-bottom: solid 1px #333;
    }


    /*menu*/
    /* CHANGED No more absolute position */
    /* trying to use inline-block elements vs absolute or floats */
    .languageModule {
        position: relative;
        top: auto;
        right: auto;

        margin-top: 0;
    }

    #menuTrigger {
        font-size: 28px;
        line-height: 60px;

        width: 100%;

        text-align: center;
        i {
            margin-left: 5px;
        }
    }

    #menu {
        padding: 0;
    }
    .container.container-navigation {
        padding-right: 0;
        padding-left: 0;
    }

    #menu-content ul {
        width: 100%;
    }
    #menu-content ul li {
        width: 100%;
        text-align: center;
        background: #ccc;
    }
    footer {
        padding: 0;
    }
    footer ul {
        width: 100%;
    }
    footer li {
        width: 100%;
        padding-left: 0;
    }
    footer .footer-navigation {
        text-align: center;
    }
    footer .social-logos {
        text-align: center;
        display: block;
    }
    .bottom .tunnel {
        margin-top: 40px;
    }
    .bottom .tunnel .row {
        margin-left: 0;
        margin-right: 0;
    }
    .view.vertical {
        padding-right: 16px;
        padding-left: 16px;
    }


    /* relative #accountMenu and new header structure */
    /* CHANGED relative positionning and optional flex order */
    .container.container-header {
        display: flex;
        flex-direction: column;
    }
    header article {
        float: none;
    }
    .logo-wrapper {
        float: none;

        margin: 0 auto;

        order: 1;
    }
    .nav-wrapper {
        overflow: visible;

        padding-bottom: 10px;

        text-align: center;

        order: 2;
    }
    header .social-logos {
        position: relative !important;
        right: auto !important;
        bottom: auto !important;

        text-align: center !important;

        order: 3;
    }
    .social-logos a {
        font-size: 20px;
        line-height: 2;

        display: inline-block;
        float: none !important;

        width: 40px;
        min-height: 40px;
        margin-top: 12px;
        margin-right: 5px;
        margin-left: 5px;
        padding: 0;

        transition: all .3s ease-out 0s;
        text-align: center;
        vertical-align: top;

        color: #3b2114;
    }
    .tunnel .hidden-xs {
        display: none !important;
    }
    .tunnel .hidden-xs.active {
        display: block !important;
    }
    .form-actions:after {
        content: ' ';
        display: table;
        clear: both;
        margin-bottom: 50px;
    }
    .view.vertical {
        padding-left: 0;
        padding-right: 0;
    }
}
/* end max-width: $mbp 767px; */

/*================================================================================*/
/*================            TABLETS & DESKTOPS                  ================*/
/*================================================================================*/

@media (min-width: $mbp-up ) {
    /* description and CTA button space for medium and big screens */
    .products .product .description {
        margin-bottom: 50px;
    }
}


/*================================================================================*/
/*================            PHONES & SMALL TABLETS              ================*/
/*================================================================================*/

@media (max-width: 992px) {
    /*menu*/
    .languageModule {
        position: absolute;
        top: 7px;
        right: auto;
        left: 5px;
        margin-top: 0;
    }

    #accountMenu,
    #logged-infos {
        top: 0;
        right: 5px;

        .fa-user {
            display: none;
        }

        .fa-angle-down {
            padding-left: 0;
        }
    }

    .extension_Login .form-vertical.login-form.logout {
        right: 5px;
    }

    .languageModule li a,
    .languageName {
        color: #555;
    }

    .languageModule a:hover {
        text-decoration: none;
    }

    #menuTrigger {
        font-size: 28px;
        line-height: 60px;
        width: 100%;
        cursor: pointer;
        text-align: center;
        i {
            margin-left: 5px;
        }
    }

    #menu {
        padding: 0!important;
    }
    .container.container-navigation {
        width: 100%;
        padding-right: 0;
        padding-left: 0;
    }
    #menu-content ul {
        width: 100%;
    }
    #menu-content li {
        width: 100%;
        text-align: center;
        background: #ccc;
    }
    footer {
        padding: 0;
    }
    footer ul {
        width: 100%;
    }
    footer ul li {
        width: 100%;
        padding-left: 0;
    }
}
/* end max-width: 992px; */

/* patch de suppression du menu fantôme 3.8.3 D */
.container-account > #logged-infos:not(:empty) + #accountMenu {
    display: none;
}


/*=========================================
=            loading icon move            =
=========================================*/
/* Loading composant  à ajouter si admin > 3.9.1 */
.loading-component {
    background-color: #FFF;
    opacity: 0.8;
    position: fixed;
    width: 100%;
    height: 100%;
    z-index: 20;
    top: 0px;
    left: 0px;
    display: none;
}

.loading-component .loading-inner {
    border: 2px solid rgba(0,0,0,0.5);
    border-top-color: rgba(0,0,0,0.08);
    border-left-color: rgba(0,0,0,0.08);
    background-color: transparent;
    border-radius: 150px !important;
    display: inline-block;
    vertical-align: middle;
    -webkit-animation: loading 1s infinite linear;
    -moz-animation: loading 1s infinite linear;
    -ms-animation: loading 1s infinite linear;
    -o-animation: loading 1s infinite linear;
    animation: loading 1s infinite linear;

    width: 100px;
    height: 100px;
    display: block;
    position: absolute;
    left: 50%;
    top: 50%;
    margin-left: -50px;
    margin-top: -50px;
}

.loading-component .loading-inner img {
    display: none;
}

@-webkit-keyframes loading {
    0% {
        -webkit-transform: rotate(0deg);
    }

    100% {
        -webkit-transform: rotate(360deg);
    }
}

@-moz-keyframes loading {
    0% {
        -moz-transform: rotate(0deg);
    }

    100% {
        -moz-transform: rotate(360deg);
    }
}

@-ms-keyframes loading {
    0% {
        -ms-transform: rotate(0deg);
    }

    100% {
        -ms-transform: rotate(360deg);
    }
}

@-o-keyframes loading {
    0% {
        -o-transform: rotate(0deg);
    }

    100% {
        -o-transform: rotate(360deg);
    }
}

@keyframes loading {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}


/* patch bootstrap 4 -> bootstrap 3 */
html {
    font-size: initial;
}

body {
    line-height: 1.5;
}


/* Suppression de select 2 et Uniform */


/* Céer un compte */
select.date-day,
select.date-month,
select.date-year {
    display: inline-block;
    width: 8em;
    margin-bottom: .5em;
}
select.date-day {
    width: 4em;
}
.control-group input[type="checkbox"] {
    display: inline-block;
}
.extension_Form label.checkable-label {
    margin-bottom: 1rem;
}
.newUser .control.formitem {
    margin: .75rem auto;
}
.newUser .formitem select {
    width: auto;
}

/* Diminution des notices rouges sur les champs email et mot de passe de l'inscription */

.formitem .required {
    font-size: .75rem;
}

/* menu loggé */
.extension_Login .form-vertical.login-form.logout .dropdown-menu {
    top: 30px;
}
header .dropdown-menu li a span,
header .dropdown-menu li a span i {
    color: #000 !important;
}

/* paragraphe avec required pour les recommandations de saisie mdp par ViJ */
.extension_Form p.required {
    font-size: 0.813em;
    margin: 0 0 5px;
}

/* Information complémentaire */
/* contexte de formatage sur la checkbox */

.popWrapper .checkText input[type="checkbox"] {
    float: left;
    display: block;
    margin-right: 10px;
}

.popWrapper .checkText label {
    overflow: hidden;
    max-width: auto;
    display: block;
}

/* panneau test footer d'AdM supprimé */
.alert_test {
    display: none;
}

/* Retour de paiement */
.order-module table.table {
    margin-left: 4%;
    margin-right: 4%;
}
.order-module .table-holder table {
    width: 92%;
}
/* dropdown connecté, override de bootstrap */
#logged-infos .nav .open > a:hover {
    background-color: transparent;
    opacity: 1;
}


/* Optimisation recap commande - alignement des extremités verticales */
.extension_Cart.col-md-12 .cart-summary {
    padding: 0 !important;
}
.extension_Cart.col-md-12 .cart-summary .ticket-info, 
.extension_Cart.col-md-12 .cart-summary table {
    width: 100%;
}
.extension_Cart.col-md-12 .total.col-md-6 {
    padding-right: 5px;
}
.extension_Cart.col-md-12 .cart-summary .cgvConditions a {
    text-decoration: underline;
}

/* optims d'alignement des marges latérales et verticales en mobile */
@media (max-width: 543px) {

    .btn {
        line-height: 1.6;
    }
    .tunnelWrapper > div {
        padding-bottom: 15px;
    }
    .cart-summary {
        padding: 10px 0! important;
    }
    .col-xs-12.tunnelWrapper {
        padding-left: 0;
        padding-right: 0;
    }
    .bottom .tunnel {
        border-top: 0;
    }
}


#copyright{
    background: #000;
    text-align: center;
}
#copyright a{
    display: inline-block;
    padding: 0.6rem 0 0.5rem 0; 
    width: 100%;
    color: #fff;
    font-style: italic;
} 
#copyright svg{
    vertical-align: middle;
    margin-left: 0.2rem;
    margin-top: -2px;
}

@if ($copyright == false) {
     #copyright{
         display: none;
     }
}


/*=================================================================================*/
/*================                   $EVOLUTIONS                   ================*/
/* ========================================================================== */

// Dette technique globale obligatoire
@import "../../../../../app/deployment_sources/common/evolutions/patches-dette-technique-globale";

// Ajoute la compatibilité IOS6, IOS7, Safari Mac 5 à 8 et IE10
// Utiliser en complément l'option auto-prefix sur le compilateur lors du traitement du fichier global.scss (avec Koala ou Gulp)
@import "../../../../../app/deployment_sources/common/evolutions/old-browsers";

// Mise en forme du message d'alerte en cas de navigation sans JavaScript
@import "../../../../../app/deployment_sources/common/evolutions/no-script";

// RGPD / GDPR : si ajout des questions vie privée en fin d'inscription
@import "../../../../../app/deployment_sources/common/evolutions/rgpd";

// Nouveau calendrier Flex avec sélection du mois en cours 
// Garder commenter pour une nouvelle charte, décommenter pour patcher un vieux site
//@import "../../../../../app/deployment_sources/common/evolutions/calendar";

/*=================================================================================*/
/*================                 $EVOLUTIONS   FIN               ================*/
/*=================================================================================*/