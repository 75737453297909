

/*========================================================================================*/
/*================                  GENERAL LAYOUT                        ================*/
/*========================================================================================*/

@import url("https://fonts.googleapis.com/css?family=Source+Sans+Pro");

/* GENERAL COLORS */
#content-inter {
    background-color: $content-bg;
    max-width: $container-width;
}

body {
    color: $text;
    background-color: $main-bg;
    font-family: "Source Sans Pro", sans-serif;
}

@media (min-width: 1200px) {
    .container {
        max-width: $container-width;
    }
}

header {
    /*background: url($header-img) repeat-x top left;*/
    background-color: $header-bg;
    border-bottom: none;
    padding-bottom: 30px;
    //min-height: 165px;
}

.nav-wrapper {
    background-color: #000;
    padding-bottom: 0;
    a,
    a span {
        color: #fff;
    }
}

.logo {
    display: block;
    background-color: #000;
}


#sub-header {
    // display: none;
}

#bfkZY5UFw9 #sub-header,
#DfMxZ6HcyQ #sub-header {
    // display: block;
    background: url("img/bg.jpg") no-repeat center center;
    min-height: 640px;
    width: 100%;
    background-size: cover;
}
.titel-billetterie {
    display: none;
}
#bfkZY5UFw9 #sub-header h1,
#DfMxZ6HcyQ #sub-header h1 {
    display: block;
    text-align: center;
    margin-top: 200px;
    color: #fff;
}
header li a,
header li a span {
    color: $header-link;
}

a {
    color: $link;
}

a:hover {
    color: $link-hover;
}

p > a {
    text-decoration: underline;
}

h1,
h2,
h3,
h4,
h5 {
    color: $title;
}



/* row spacing */
.row {
    margin-top: 5px;
    margin-bottom: 5px;
}

//gestion des langues à la hussarde, désolé, pas le choix.
.lang-fr-FR .en,
.lang-en-EN .fr,
.lang-en-GB .fr {
    display: none;
}

/*===========================================================================================*/
/*================                        HEADER                             ================*/
/*===========================================================================================*/

.siteLogo {
    display: block;
    width: 224px;
    margin-top: 0;
    padding: 0 15px;
    background: #000 url($logo-client) no-repeat center;
    background-size: 224px 82px;
    min-height: 82px;
    margin-right: 15px;
}


@media (min-width: 982px) {
    .siteLogo {
        display: none;
    }
}

.siteLogo:hover {
    opacity: 1;
}

.client-menu {
    ul {
        list-style: none;
        margin-top: 0;
        padding-left: 0.75rem;
        li {
            display: inline-block;
            padding: 0;
            margin: 0;
            a {
                padding: 0;
                margin-right: 2px;
            }

            img {
                width: auto;
                height: 82px;
            }
        }
    }
}

a.home-billet {
    vertical-align: middle;
    // réglage de l'alignement vertical en cas de réduction de taille de police :
    // utiliser le padding-top
    padding-top: 6px;
    display: inline-block;
    a.home-billet {
        color: #fff;
        font-size: 1rem;
    }
}

a.home-billet:focus,
a.home-billet:hover {
    opacity: 1;
    color: #fff !important;
    text-decoration: none;
}

@media (min-width: $mbp-up) and (max-width: 1023px) {
    header article.extension_Language .languageModule {
        float: right;
        position: relative;
        margin-bottom: 10px;
    }

    #accountMenu {
        margin-top: 0;
    }
}

/* pas de multilingue */
/*.nav-wrapper .extension_Language  {
display: none;
}*/

#languageMenu {
    display: inline-block;
    vertical-align: text-top;
}
.activeLanguageDisplayer + ul li {
    text-align: left;
}
@if ($language-module-orientation == "horizontal") {
    /* header {
        min-height: 45px;
    } */
} @else {
    header {
        /*height: 115px;*/
    }
}

/*===========================================================================================*/
/*================                        FOOTER                         ================*/
/*===========================================================================================*/
.footer-logo {
    /*background: url("img/flv_nb.jpg") no-repeat 0 -12px;*/
    height: 50px;

    background-size: contain;
}

footer {
    border-top: solid 1px $footer-text;
    border-bottom: solid 1px $footer-text;
    background-color: $footer-bg;
    padding: 12px;
}
@if ($footer-logo) {
    .footer-logo {
        display: none;
    }
    footer ul.nav {
        border-left: #333 solid 1px;
        box-shadow: -1px 0 0 #555;
    }
}

@if ($footer-menu != "inline") {
    footer ul.nav li {
        display: block !important;
        width: 100%;
    }
    @media (min-width: $mbp-up) {
        footer ul.nav li {
            padding-left: 20px;
        }
    }
} @else {
    footer li {
        padding: 0 10px 0 10px;
        width: auto;
        border-right: solid 1px #fff;
        a {
            padding: 0 !important;
            text-transform: uppercase;
        }
    }

    footer li:last-child {
        border-right: none;
    }
}

footer span {
    color: $footer-text;
}

footer a span {
    color: $footer-link;
}

footer a:hover span {
    opacity: 1;
    color: $footer-link-hover;
}

/*========================================================================================*/
/*================                       SOCIAL LOGOS                     ================*/
/*========================================================================================*/
.social-logos {
    display: block;
}

header .social-logos {
    position: absolute;
    right: 0;
    bottom: 4px;

    text-align: right;
}

.social-logos a {
    font-size: 20px;
    //line-height: 2;

    width: 40px;
    height: 40px;
    margin-top: 12px;
    margin-right: 5px;
    margin-left: 5px;
    padding: 0;

    transition: $button-transition;
    text-align: center;
    vertical-align: top;

    color: $link;
}

.social-logos a:focus,
.social-logos a:hover {
    color: $link-hover;
}

footer .social-logos a {
    color: $footer-link;
}

footer .social-logos a:focus,
footer .social-logos a:hover {
    color: $footer-link-hover;
}

/*========================================================================================*/
/*================                       MENUS                            ================*/
/*========================================================================================*/
#logged-infos a,
.dropdown .fa,
.dropdown .username,
header li a {
    color: $header-link;
}

#logged-infos .username {
    padding: 10px;
}

#logged-infos a.dropdown .username {
    color: $loggedinfo-user;
}
.dropdown-menu {
    background: $loggedinfo-bg;
}
.dropdown-menu a {
    color: $loggedinfo-link !important;
}

#logged-infos {
    .nav .open > a {
        background: none;
    }
}

.gravatar-user-wrapper {
    display: none;
}

#logged-infos {
    vertical-align: middle;
    padding-top: 5px;
    padding-left: 10px;
}

/* logged menu */
.extension_Login .form-vertical.login-form.logout .dropdown-menu {
    top: 30px;
}
header .dropdown-menu li a span,
header .dropdown-menu li a span i {
    color: #000 !important;
}

/*language-selector*/
.extension_Language {
    border-left: solid 1px #fff;
    border-right: solid 1px #fff;
    padding: 0 20px;
}

.extension_Language,
.languageModule {
    background-color: $activelanguage-bg;

    ul,
    .activeLanguageDisplayer {
        background: $language-bg;
        color: $language-text;
    }

    li.active,
    .active a.languageName {
        background-color: $activelanguage-bg;
        text-decoration: underline;
        a {
            color: $activelanguage-text;
        }
    }

    li {
        a {
            color: $language-text;
            padding: 0;
            line-height: 1.9rem;
        }
    }
}
header .languageModule li:not(.active) a:hover {
    color: $language-text;
}

/* horizontal languageMenu - inline links */
/*
.languageModule {
border-radius: 0 !important;
line-height: 1.2;
}
.languageModule ul {
background-color: white;
border: 0 none;
display: block;
}
.activeLanguageDisplayer {
display: none;
}
.languageModule li {
border-radius: 0;
display: inline-block;
margin-right: -4px;
padding: 0;
text-align: center;
}
.languageModule li, .languageModule li:last-child {
display: inline-block;
}
.activeLanguageDisplayer {
text-transform: uppercase;
}
.languageModule .activeLanguageDisplayer {
padding: 0 8px;
text-align: center;
}
.languageModule li:last-child, .languageModule li, .languageModule li:hover, .languageModule ul {
border-radius: 0;
}
a.languageName {
background-color: #dddddd;
display: block;
font-family: Helvetica,Arial,sans-serif;
font-size: 12px;
line-height: 1.4;
padding: 7px 10px 4px;
text-align: center;
text-transform: uppercase;
}
.languageModule, .extension_Language li a, .languageModule li a {
color: #5b5a54;
}
.active a.languageName {
background-color: #5b5a54;
color: white;
}
.activeLanguageDisplayer img, .languageFlag {
display: none;
}
*/
/* end of horizontal languageMenu - inline links */

#menu {
    display: none;
    /*background-color: $menu-bg;
    min-height: 60px;
    border-bottom: 1px solid rgba(91, 90, 84, 0.6);
    padding: 0 40px;

    li {
    display: inline-block;
}

    li:hover {
    border-top: 5px solid $menu-border;
}

    a {
    color: $menu-link;
}*/
}

/*========================================================================================*/
/*================                        BOUTONS                         ================*/
/*========================================================================================*/

.btn {
    border-radius: 0;
    transition: $button-transition;
    text-transform: uppercase;
    font-size: 14px;
}

/*========================================================================================*/
/*================                      COOKIE POPIN                      ================*/
/*========================================================================================*/

.cookie-banner {
    position: fixed;
    z-index: 100000;
    left: 0;

    width: 100%;

    text-align: center;
}
.cookie-banner-message {
    margin: 0;
    padding: 10px;

    color: #fff;
    background-color: #3a3936;
}
.cookie-banner-message a {
    text-decoration: underline;

    color: #fff;
}
.cookie-banner-message .btn-save,
.popin-cookie .btn-save,
.cookie-banner-message a.btn-save,
.popin-cookie a.btn-save {
    display: inline-block;

    margin: 5px 2px;
    padding: 10px 16px;

    transition: $button-transition;
    text-decoration: none;

    color: $btn-cookie-text;
    border: none;
    background-color: $btn-cookie;
}

.cookie-banner-message .btn-save:hover,
.popin-cookie .btn-save:hover {
    background-color: darken($btn-cookie, 10%);
}

.popin-cookie {
    position: absolute;
    z-index: 10000;
    top: 40%;
    left: 50%;

    width: 300px;
    margin-top: 0;
    margin-left: -150px;
    padding: 10px 10px;

    text-align: center;

    opacity: 1;
    border: 1px solid #ccc;
    background-color: #fff;
    box-shadow: 0 0 30px 1px #797979;
}
.cookie-msg {
    font-size: 12px;

    z-index: 10;

    padding: 5px;

    text-align: center;

    border: 1px solid #ccc;
    background-color: #fff;
    box-shadow: 0 0 30px 1px #797979;
}

/*========================================================================================*/
/*================                            ERRORS                      ================*/
/*========================================================================================*/

.ticket-error.form-general-error {
    margin-top: 5px;
    position: relative;
}

.ticket-error {
    color: $error-text;
    border: 1px solid $error-text;
}

#error {
    color: $error-text;
    border: 1px solid $error-text;
}

/*============================================================================================*/
/*================                        FORMULAIRES                         ================*/
/*============================================================================================*/

/* override component CSS : replacement fonts */
.formitem label {
    font-family: "Open Sans", Arial, sans-serif !important;
}

textarea,
input[type="text"],
input[type="password"],
input[type="datetime"],
input[type="datetime-local"],
input[type="date"],
input[type="month"],
input[type="time"],
input[type="week"],
input[type="number"],
input[type="email"],
input[type="url"],
input[type="search"],
input[type="tel"],
input[type="color"],
.uneditable-input,
.login-form input.m-wrap[type="text"],
.login-form input.m-wrap[type="password"],
.forget-form input.m-wrap[type="text"] {
    color: $input-text;
}

.login-form label,
.forget-form label {
    color: $text;
}

.login-form input.m-wrap[type="text"],
.login-form input.m-wrap[type="password"],
.forget-form input.m-wrap[type="text"] {
    color: $input-text;
}

/*========================================================================================*/
/*================                        FAMILY                          ================*/
/*========================================================================================*/

.products .short > br:first-child,
.products .short > p + br {
    display: none;
}
.default .products .row .product.col-md-6 .btn {
    font-size: 1em;
    color: $btn-text;
    height: auto;
    border-radius: 0 !important;
}
.default .products .row .product.col-md-6 .infos-container {
    min-height: 100px;
}

.description .long.description {
    height: auto !important;
}
.description .long.description .descrToggle {
    display: none;
}

@media (min-width: $mbp-up) {
    .products .product .description {
        margin-bottom: 40px;
    }
    /*     .products .product .description + .btn {
        right: 15px;
    } */
}
.extension_Familly .description {
	.btn.descrToggle{
		display: none;
	}
	.long.description{
		display: block !important;
	}
}

/*========================================================================================*/
/*================                        TUNNEL                          ================*/
/*========================================================================================*/

.default .tunnel .step .number {
    color: $tunnel-number;
    border-color: $tunnel-text;
    background: $tunnel-bg;
    line-height: 22px;
}

.default .tunnel .step .text {
    color: $tunnel-text;
}

.default .tunnel .step.complete .number {
    color: $tunnel-complete-number;
    border-color: $tunnel-complete-bg;
    background: $tunnel-complete-bg;
    line-height: 33px;
}

.default .tunnel .step.complete .text {
    color: $tunnel-complete-text;
}

.default .tunnel .step.active .number {
    color: $tunnel-active-number;
    border-color: $tunnel-active-bg;
    background: $tunnel-active-bg;
}

.default .tunnel .step.active .text {
    color: $tunnel-active-text;
}

/* TODO: table de la vue récap à cibler autrement que par une classe Bootstrap ! */
.extension_Cart.col-md-12 table {
    font-size: 0.875rem;
}

/* tooltip :  curseur */
i.fa[data-toggle="tooltip"] {
    cursor: help;
}

/*========================================================================================*/
/*================                        PRODUITS                        ================*/
/*========================================================================================*/
.product .product-title {
    font-size: 1.5rem;
    font-weight: normal;
    text-transform: uppercase;
    color: #111;
    //color: $title;
}

.element-text p:last-child {
    margin-bottom: 15px;
}

/* Récap commande + gros */
.col-md-12.extension_Cart .cart-summary {
    font-size: 0.875rem !important;
}

.order-teaser .btn:hover {
    background-color: #57bd42 !important;
}

#module_lt5PgVg7kG .bottom .tunnel {
    margin-top: 0;
}
#module_YoSjiYuXap h4 {
    margin: 0 1.3rem 0 0;
    padding: 0;
    float: right;
}

/*========================================================================================*/
/*================                        SEANCES                         ================*/
/*========================================================================================*/
article[data-action="SessionsGrid"].extension_Calendar
    .session.selected
    .sessionWrapper {
    background-color: $seances-bg;
    border-color: $seances-bg;
    color: $seances-color;
}

/*========================================================================================*/
/*================                        PANIER                          ================*/
/*========================================================================================*/
.extension_Cart .cart-summary .products tr.odd,
.extension_Cart .cart-summary .products tr.even {
    background-color: $cart-bg;
}

.cart-summary .ticket-info {
    color: $cart-sum-text;
    border: $cart-sum-bg;
    background: $cart-sum-bg;
}

/*===========================================================================================*/
/*================                        CALENDRIER                         ================*/
/*===========================================================================================*/

.extension_Booking .booking .day-background {
    background-color: $day-bg;
}

.extension_Booking .booking .force-enabled-color {
    background-color: $day-force-enabled-color;
}
.extension_Booking .booking .force-disabled-color {
    background-color: $day-force-disabled-color;
}

.extension_Booking .booking .day.active .day-background,
.extension_Booking .booking .day.enabled.active .day-background {
    border-color: $day-active-bg;
    background-color: $day-active-bg;
}

.extension_Booking .booking .day-labels {
    border-bottom: 1px solid $day-labels-bg;
    background-color: $day-labels-bg;
}

.booking .header *,
.booking .header a {
    color: $booking-header-text;
}

.booking .content .day.active span {
    color: $day-number-text;
}
.booking .content .day.active .price {
    color: $price-text;
}

/* nouveau calendrier flex */
article.extension_Calendar[data-action="Default"] .selected,
article.extension_Calendar[data-action="Calendar"] .selected {
    background: $day-selected-bg !important;
    color: $day-selected-text;
}
@media (min-width: 982px) and (max-width: 1200px) {
    .dayName .long {
        font-size: 0.875em;
    }
}
@media (max-width: 359px) {
    .monthChooser {
        font-size: 12px;
        padding: 4px;
    }
    .dayName {
        padding-left: 5px !important;
        padding-right: 5px !important;
    }
}

/* selection de séance */
article.extension_Calendar[data-action="Sessions"] .session.selected {
    background-color: $session-selected-bg !important;
    border-color: $session-selected-bg !important;
    color: $session-selected-text;
}

.calendarHeader {
    padding-top: 1rem;
}
.carousel-control {
    font-size: 20px;
    color: $calendar-control;
    text-shadow: none;
    background-color: transparent;
    background-image: none !important;
    filter: alpha(opacity=1);
    opacity: 1;
}
#monthsCarousel .item button.active,
.monthsCarousel .item button.active,
#monthsCarousel .item button:focus:not(.active),
#monthsCarousel .item button:hover:not(.active) {
    background-color: $calendar-control-active;
}
.carousel-control:focus,
.carousel-control:hover,
.yearSwitcher .previous:focus:not(.disabled),
.yearSwitcher .next:focus:not(.disabled),
.yearSwitcher .previous:hover:not(.disabled),
.yearSwitcher .next:hover:not(.disabled) {
    color: $calendar-control-active;
    cursor: pointer;
}
article.extension_Calendar[data-action="Default"] .disabled,
article.extension_Calendar[data-action="Calendar"] .disabled {
    background-color: transparent !important;
    color: $calendar-control-disabled;
}
.day.before {
    border-color: #ccc;
    color: transparent !important;
    opacity: 0.7;
}

@media (min-width: 800px) {
    .actualYear,
    article.extension_Calendar[data-action="Default"] .day .dayDate,
    article.extension_Calendar[data-action="Calendar"] .day .dayDate,
    article.extension_Calendar[data-action="Default"] .dayName,
    article.extension_Calendar[data-action="Calendar"] .dayName {
        font-size: 1.125em;
    }
}

/*===========================================================================================*/
/*================                                           CHOIX PARTICIPANTS                                                ================*/
/*===========================================================================================*/

.extension_Participan {
    .header,
    .line {
        display: flex;
        justify-content: flex-end;
        margin: 0 5px;
    }

    .header {
        font-weight: bold;
        padding-bottom: 5px;
    }

    .family-name {
        flex: 1;
    }

    .header-unit-price,
    .header-quantity,
    .header-total {
        /*         margin-left: 25px; */
        width: 10%;
        text-align: right;
    }

    .unit-price,
    .quantity,
    .total {
        width: 10%;
        text-align: right;
    }

    .line {
        display: flex;
        border-top: solid 1px #ddd;
        border-bottom: solid 1px #ddd;
        align-items: center;
    }

    .name,
    .date,
    .unit-price,
    .quantity,
    .total,
    .pic {
        align-items: stretch;
        height: 100%;
        padding: 10px 0;
    }

    .pic {
        border-right: solid 1px #ddd;
        border-left: solid 1px #ddd;
        font-size: 1.5em;
    }

    .pic,
    .name {
        padding-left: 15px;
        padding-right: 15px;
    }

    .date {
        flex: 1;
        text-align: center;
    }

    .users {
        display: flex;
        justify-content: flex-start;
        flex-wrap: wrap;
    }

    .user {
        margin: 5px;
        padding: 10px;
        background: #dddddd;
        max-width: 19%;
    }

    .avatar {
        img {
            max-width: 100%;
        }
    }

    .user-name input {
        width: 100%;
        margin-bottom: 10px;
    }
}

#ModalPhoto .modal-content {
    min-height: 0;
}

.list-photo {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    img {
        max-width: 10%;
        margin: 1px;
        max-height: 100px;
    }
}

/*===========================================================================================*/
/*================                        DARK THEME                         ================*/
/*===========================================================================================*/
@if ($dark-theme) {
    .default .tunnel,
    .extension_Products .product.odd,
    .product.even,
    article[data-action="Default"].extension_Calendar #calendar,
    article[data-action="Calendar"].extension_Calendar #calendar,
    .cart-summary .roomList li .moreInfos,
    .cart-summary,
    .extension_Cart .cart-summary .products tr.odd,
    .extension_Cart .cart-summary .products tr.even,
    .table-striped tbody > tr:nth-child(2n + 1) > td,
    .table-striped tbody > tr:nth-child(2n + 1) > th {
        background: $dark-background;
        border: solid 1px $dark-border;
    }

    .extension_Products .productLine {
        background: $dark-background !important;
        border-bottom: solid 1px $dark-product-separator;
    }

    .product select {
        background: $dark-background;
    }

    article[data-action="SessionsGrid"].extension_Calendar
        .session
        .sessionWrapper
        .remaining,
    article[data-action="SessionsGrid"].extension_Calendar
        .session
        .sessionWrapper
        .hour,
    .cart-summary *,
    .table-striped tbody > tr:nth-child(2n + 1) > td,
    .table-striped tbody > tr:nth-child(2n + 1) > th,
    .table-holder table thead tr th,
    legend,
    .formitem label {
        color: $dark-text-color;
    }

    .table-holder table thead tr:first-child {
        background: #222;
        color: $dark-text-color;
    }
}

.vignette .product a.btn:hover {
    background-color: transparent !important;
}

/*dropdown menu*/

.client-menu-dropdown {
    margin-top: 40px;

    .navbar-nav > li > a {
        color: #666666;
        text-transform: uppercase;
        font-size: 20px;
        font-weight: 500;
        padding: 12px 23px 12px 12px;
        z-index: 102;
        border: 1px solid transparent;
        bottom: -1px;
        -webkit-transition: none;
        -o-transition: none;
        transition: none;
    }

    .dropdown > a:before {
        font-family: "FontAwesome";
        content: "\f107";
        position: absolute;
        left: auto;
        top: 12px;
        right: 5px;
        line-height: 1;
        color: #d1d1d1;
    }

    .navbar-nav > li > a {
        color: #666666;
        text-transform: uppercase;
        font-size: 20px;
        font-weight: 500;
        padding: 12px 23px 12px 12px;
        z-index: 102;
        border: 1px solid transparent;
        bottom: -1px;
        -webkit-transition: none;
        -o-transition: none;
        transition: none;
    }

    .dropdown-menu > li > a {
        padding: 12px 20px;
        font-size: 15px;
        color: #666666;
        border-bottom: 1px solid #f1f1f1;
        -webkit-transition: none;
        -o-transition: none;
        transition: none;
    }

    .dropdown-menu > li > a:hover,
    .dropdown-menu > li > a:focus,
    .nav .open > a,
    .nav .open > a:hover,
    .nav .open > a:focus,
    .dropdown-menu > .active > a,
    .dropdown-menu > .active > a:hover,
    .dropdown-menu > .active > a:focus,
    .dropdown-menu .menu > .active > a,
    .dropdown-menu .menu > .active > a:hover,
    .dropdown-menu .menu > .active > a:focus {
        background-color: #ffffff;
        color: #000000 !important;
        border-color: #f1f1f1;
    }

    .main-navigation .navbar-nav > .open > a,
    .main-navigation .navbar-nav > .open > a:hover,
    .main-navigation .navbar-nav > .open > a:focus {
        color: #666666;
        background-color: #fafafa;
    }

    header .nav a:hover,
    header .nav a:focus {
        opacity: 1 !important;
    }

    .dropdown-menu {
        min-width: 240px;
        -webkit-border-radius: 0px;
        -moz-border-radius: 0px;
        border-radius: 0px;
        padding: 0;
        margin: 0;
        background-color: #fafafa;
        -webkit-box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.08);
        box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.08);
        border: 1px solid #f1f1f1;
        z-index: 101;
    }

    .dropdown-toggle:hover {
        background-color: #fafafa !important;
    }

    .nav > li.dropdown:hover .dropdown-menu {
        //display: none !important;
    }
}

/*===========================================================================================*/
/*================                        RESPONSIVE                         ================*/
/*===========================================================================================*/

#menuTrigger {
    color: $menu-link;
}

@media (max-width: 1200px) {
    .client-menu-dropdown .navbar-nav > li > a {
        font-size: 16px;
    }
}

@media (max-width: 992px) {
    .extension_Language .activeLanguageDisplayer,
    .languageModule {
        background: none;
    }
    footer ul.nav {
        border-left: none;
        box-shadow: none;
    }
    .social-logos {
        padding-left: 0 !important;
    }
    .social-logos a {
        margin-right: 10px;
        margin-left: 10px;
    }
    #languageMenu {
        margin-left: 0;
    }
    header article.extension_Language li:not(.active) .languageName {
        color: #fff;
    }

    .home-billet {
        padding-top: 0;
    }

    .client-menu-dropdown .dropdown {
        width: 100%;
        margin-left: 0;
        background: #fafafa;
        border-bottom: 1px solid #f1f1f1;

        ul {
            position: static !important;
            float: none;
        }
    }

    .nav > li.entite {
        width: 100%;
        margin-left: 0;
        text-align: right;
        font-style: italic;
        background: #fafafa;
        border-bottom: 1px solid #f1f1f1;
    }

    .dropdown-menu {
        background: #fff;
    }

    .client-menu-dropdown {
        margin-top: 80px;
    }
}

@media (max-width: $mbp) {
    .language-menu-wrapper {
        display: block !important;
        right: 10px;
        left: auto;
        position: absolute !important;
        top: -10px;
        z-index: 1000;
        width: 55px;
    }
    header article {
        margin-left: 0;
    }
    a.home-billet {
        left: 15px;
        position: absolute !important;
        top: 0;
        z-index: 1000;
    }

    .nav-wrapper {
        padding-bottom: 10px;
    }

    .extension_Language li a,
    .languageModule li a {
        line-height: inherit;
    }

    .vignette {
        .overlay,
        .fakeImg,
        .description,
        a.btn {
            width: 100% !important;
        }
    }
}

@media (min-width: 768px) {
    .languageModule {
        min-width: 0;
    }
}

/*@media (max-width: 1200px) {
.container {
max-width: 1000px;
}
}*/

/* iframe popup */
/*************************/
/* IMPORTANT : la classe popup-page traitée ci-dessous doit être renseignée comme classe de la page dans le CMS */
/*************************/

/* white iframe popup body */

@media (min-width: 980px) {
    .modal-dialog {
        width: 980px;
        margin: 30px auto;
    }
    .modal-content {
        min-height: 800px;
    }
    .modal-dialog iframe {
        left: 0 !important;
        min-height: 720px;
    }
}

.popup-body {
    margin: 0 auto !important;
}

body.popup-page {
    background-image: none;
    background-color: #fff;
}

.popup-page #content {
    margin-top: 0;
}

/* patch bootstrap 4 -> bootstrap 3 */
html {
    font-size: initial;
}

body {
    line-height: 1.5;
}

/* Suppression de select 2 et Uniform */

/* Céer un compte */
select.date-day,
select.date-month,
select.date-year {
    display: inline-block;
    width: 8em;
    margin-bottom: 0.5em;
}
select.date-day {
    width: 4em;
}
.control-group input[type="checkbox"] {
    display: inline-block;
}
.extension_Form label.checkable-label {
    margin-bottom: 1rem;
}




/* ==========================================================================
    2019.08.30 - BaT : Suppression bandeau header 
   ========================================================================== */

#languageMenu li:nth-child(1){
    display:none;
}

#sub-header {
    display: none;
}

header {
    padding-bottom: 0;
}

#content {
    margin: 0;
}


/* select notif */

.extension_Products .product .row.notification select {
    color: #000 !important;
}

.mixed .products .product .row.notification {
    background-color: #000 !important;
    color: #fff;
}