// Horizontal language menu

$language-module-orientation: "horizontal";

// Specific CSS

#languageMenu {
    margin-left: 16px;
}
.extension_Language,
.languageModule {
    background: transparent !important;
}
.languageModule {
    border-radius: 0 !important;
    line-height: 1.2;
}
.languageModule ul {
    background-color: transparent !important;
    border: 0 none;
    display: block;
}
.languageModule ul:hover {
  display: block !important;
}
.activeLanguageDisplayer {
    display: none;
}
.languageModule li {
    border-radius: 0;
    display: inline-block;
    //margin-right: -4px;
    padding: 0;
    text-align: center;
}
.languageModule li, .languageModule li:last-child {
    display: inline-block;
}
.activeLanguageDisplayer {
    text-transform: uppercase;
}
.languageModule .activeLanguageDisplayer {
    padding: 0 8px;
    text-align: center;
}
.languageModule li:last-child, .languageModule li, .languageModule li:hover, .languageModule ul {
    border-radius: 0;
}
a.languageName {
    background-color: $language-bg;
    display: block;
    font-size: 12px;
    line-height: 1.4;
    padding: 7px 10px 4px;
    text-align: center;
    text-transform: uppercase;
}
.languageModule, .extension_Language li a, .languageModule li a {
    color: #5b5a54;
}
.active a.languageName {
    background-color: #5b5a54;
    color: white;
}
.activeLanguageDisplayer img, .languageFlag {
    display: none;
}
@media (max-width: $mbp) {
    .logo-wrapper {
        margin-top: 30px !important;
    }
}
@media (min-width: $mbp-up) {
    .language-menu-wrapper {
        position: relative;
        display: inline-block;
    }
}
