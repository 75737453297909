/* Masquage CSS des éléments traduits en dur dans l'HTML */
/* Solution de secours ! */

.lang-fr-FR .en-GB,
.lang-fr-FR .es-ES,

.lang-en-GB .fr-FR,
.lang-en-GB .es-ES,

.lang-es-ES .fr-FR,
.lang-es-ES .en-GB
{
  display: none;
}