
/*=============================================================================================================================================*/
/*================                                                                                                             ================*/
/*================                                                CSS du site client.                                          ================*/
/*================                                          Variables de customisation du theme                                ================*/
/*================                                                                                                             ================*/
/*=============================================================================================================================================*/

/* Table of content

$ACCESSIBILITY
$INTERNATIONALISATION
$IMAGES
$DEFAULT THEME CUSTOMISATION
$DEFAULT COLORS
$DEFAULT FONTS
$CHILD THEME
$EFFECTS
$COMPATIBILITY

*/


/*=================================================================================*/
/*================                 $ACCESSIBILITY                  ================*/
/*=================================================================================*/

/* teste l'accessibilité en local avec CSS : entoure toutes les mauvaises pratiques */
/* laissez en commentaire pour la mise en prod !!! */
//@import "scss/a11y/a11y-fr";



/*=================================================================================*/
/*================                 $INTERNATIONALISATION           ================*/
/*=================================================================================*/

/* Un bien grand mot ! solution de secours pour les textes multilingues en dur dans master.html */
/* laissez en commentaire si pas besoin de ce patch */
//@import "scss/l18n/l18n.scss";



/*=================================================================================*/
/*================                 $IMAGES                         ================*/
/*=================================================================================*/
$logo-client: "img/logo.png";
/*Si un logo en x2 est dispo, décommenter la ligne suivante
et la règle .siteLogo dans la media query densité de pixel >= 2 à la fin de master.scss
en spécifiant bien la taille en 100%
Sinon laisser tel quel */
/*$logo-client-2x : "img/logo@2x.png";*/
$header-img: "img/header.png";
$showcase: "img/showcase.jpg";


/*======================================================================================*/
/*================           $DEFAULT THEME CUSTOMISATION               ================*/
/*======================================================================================*/

/* layout and style without child-theme */
$logo-position:               "left"; // "left"(default), "right" or center

$dark-theme:                false;// true => content on dark background
$header-dark:                 false; // true => dark bg or false => light bg
$footer-dark:                 true; // true => dark bg or false => light bg
$footer-logo:                 false; // true => with footer logo, false => no footer logo
$copyright:                   true;// true => Active copyright
$container-width:           1440px;


// DONE
/* Main breakpoint */
$mbp: "767px";
$mbp-up: "768px";

/* transition speed & effect */
// transition for all buttons ()
$default-transition:          all 0.3s ease-in-out;
$button-transition:           $default-transition;
$link-transition:             $default-transition;
$menu-transition:             $default-transition;
$input-transition:            $default-transition;


/*======================================================================================*/
/*================               $DEFAULT FONTS                         ================*/
/*======================================================================================*/

/* Fonts */
/* default fonts */
/*=================================================================================*/
/*================                  FONTS                         =================*/
/*=================================================================================*/

/* self-hosted font integration */

@font-face {
    font-family: "calibre";
    src: url("fonts/calibre-web-regular.woff") format("woff");
    font-weight: normal;
}
@font-face {
    font-family: "calibre-web-bold";
    src: url("fonts/calibre-web-bold.woff") format("woff");
    font-weight: normal;
}

@import 'https://fonts.googleapis.com/css?family=Open+Sans';
$base-font: 'calibre', 'Open Sans', Arial, sans-serif;
$title-font: 'calibre-web-bold', 'Open Sans', Arial, sans-serif;

// if no child-theme, import your webfonts here :
//@import 'https://fonts.googleapis.com/css?family=Lato';


/*=================================================================================*/
/*================     $DEFAULT COLORS (for the default theme)     ================*/
/*=================================================================================*/
/* dark theme */

@if ($dark-theme == true) {
    $dark-background : #000;
    $dark-border : #333;
    $dark-product-separator : #666;
    $dark-text-color : #fff;
}

/* header */

// header light (default)
$header-bg: #fff;
$loggedinfo-bg: #fff;
$header-text: #333;
$header-link: #fff;
$loggedinfo-user: #fff;
$loggedinfo-link: #222;
$language-text: #fff;
$activelanguage-text: #fff;
$language-bg: #000;
$activelanguage-bg: #000;

@if ($header-dark == true) {
    // header dark
    $header-bg: #000;
    $loggedinfo-bg: #fff;
    $header-text: #fff;
    $header-link: #fff;
    $loggedinfo-user: #fff;
    $loggedinfo-link: #000;
    $language-text: #000;
    $activelanguage-text: #fff;
    $language-bg: #fefefe;
    $activelanguage-bg: #333;
}

/* menu */
$menu-bg: #fff;
$menu-border: #5B5A54;
$menu-text: #5B5A54;
$menu-link: #5b5a54;
$menu-link-hover: #555;

/* main */
$text: #333;
$highlight-text: red;
$main-bg: #fff;
$content-bg: #fff;

/* titles*/
$title: #333;
$h1: #333;
$h2: #333;
$h3: #333;
$h4: #333;
$h5: #333;

/*links*/
$link: #5b5a54;
$link-hover: #b51f1f;

/* forms */
$error-text: #ff0000;
$input-text: #555;

/*buttons*/
/* classic button */
$btn: #000;
$btn-text: #fff;
$btn-hover: #fff;
$btn-text-hover: #000;

/* link button (no background) */
$btn-link-text: #5b5a54;
$btn-link-text-hover: #333;

/* tunnel */
$tunnel-text: #ccc;
$tunnel-bg: #eee;
$tunnel-number: #ccc;
$tunnel-complete-bg: #000;
$tunnel-complete-text: #ccc;
$tunnel-active-bg: #000;
$tunnel-active-text: #5B5A54;
$tunnel-complete-number: #fff;
$tunnel-active-number: #fff;
$seances-bg: #000;
$seances-color : #fff;

/* cart */
$cart-bg: #fff;
$cart-sum-bg: #3A3936;
$cart-sum-text: #ccc;

/* calendrier */
$day-bg: #efeee6;
$day-active-bg: #2f879b;
$day-labels-bg: #fff;
$calendar-header-bg: #fff;
$booking-header-text: #333;
$day-number-text: #fff;
$price-text: #fff;
$day-force-enabled-color: #eee;
$day-force-disabled-color: #ccc;

/* calendrier flex */
$day-selected-bg: #ff7f00;
$day-selected-text: #000;
$calendar-control: #565550;
$calendar-control-active: #000;
$calendar-control-disabled: #ccc;

/* selection séance */
$session-selected-bg: #ff7f00;
$session-selected-text: #000;

/* footers */
$footer-text: #000;
$footer-bg: #fefefe;
$copyright-bg: #3A3936;
$footer-link: #000;
$footer-link-hover: #555;

@if ($footer-dark == true) {
    $footer-text: #fff;
    $footer-bg: #000;
    $copyright-bg: #3A3936;
    $footer-link: #fff;
    $footer-link-hover: #dbdad3;
}

/* cookie footer banner */
$btn-cookie: $btn;
$btn-cookie-text: $btn-text;

/*=================================================================================*/
/*================                 $INTERNATIONALISATION           ================*/
/*=================================================================================*/

/* Un bien grand mot ! solution de secours pour les textes multilingues en dur dans master.html */
/* laissez en commentaire si pas besoin de ce patch */
@import "scss/l18n/l18n";

/*======================================================================================*/
/*================                    $CHILD THEME                      ================*/
/*================     Override colors vars and choose fonts & layout   ================*/
/*======================================================================================*/

/* import child theme if needed */

//@import "scss/child-themes/chateau";
//@import "scss/child-themes/attraction";
//@import "scss/child-themes/musee";

/*======================================================================================*/
/*================                    $LAYOUT                           ================*/
/*================     Needs to be set up after the theme colors        ================*/
/*======================================================================================*/
// Choose language menu orientation: "vertical" or "horizontal"
@import "scss/layouts/language-horizontal";
//@import "scss/layouts/language-vertical";

// footer menu block / inline
//$footer-menu:  "block";
@import "scss/layouts/footer-menu-inline";

/*======================================================================================*/
/*================                    $EFFECTS                          ================*/
/*================     Needs to be set up after the theme colors        ================*/
/*======================================================================================*/

// button effect : fade, sweep-to-right, sweep-to-top, sweep-to-bottom, border, underline, grow-shadow
@import "scss/effects/fade";
// link effect : color, opacity, link-underline
@import "scss/effects/color";
// menu styling : menu (default), menu-custom
@import "scss/layouts/menu";


// SASS _variables end
