$menu-style:  "menu";

/*==========================================================================================*/
/*================                   MENU                                   ================*/
/*==========================================================================================*/

#menu .nav {
    margin-bottom: 0;
}

#menu li {
    line-height: 55px;

    margin: 0;
    padding: 0 20px;

    transition: $menu-transition;

    border-top: 5px solid #fff;
}

#menu li:hover {
    transition: $menu-transition;

    border-top: 5px solid #5b5a54;
}

#menu a {
    transition: $menu-transition;
    text-transform: uppercase;
}

#menu a:focus,
#menu a:hover {
    transition: $menu-transition;

    opacity: .5;
    background-color: transparent;
}
