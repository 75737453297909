/* Mise en forme de la balise noscript à l'ouverture de la balise main */

#no-script{
    text-align: center;
    display: block;
    background: rgb(158, 158, 158) !important;
    width:100%;
    color: #fff !important;
}

#no-script p:nth-child(1){
    font-size: 3rem
}

#no-script p {
    padding: 1rem 0;
}