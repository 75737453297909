/* Dette technique globale des CSS modules GAT et de Dagger */

/* ==========================================================================
   Popin maintenance
   ========================================================================== */
#myPasswordModal .modal-dialog {
	width: 19rem !important;
}
#myPasswordModal .modal-content {
	min-height: 10rem !important;
}

/* ==========================================================================
   Script tarteaucitron.js
   ========================================================================== */
.tarteaucitronSelfLink img {
    width: inherit;
    height: inherit;
}

/* ==========================================================================
   Familly subfamily
   ========================================================================== */

/* si une seule famille via le site instit (comme chez Vranken) => on centre le bloc */
.subfamily:only-child {
   margin: 0 auto;
}

/* ==========================================================================
   Module products
   ========================================================================== */

/* on évite la confusion .families .products et body.products dans les tunnels EN sinon plus de scroll possible */
body.products {
	overflow: inherit;
}

/* vue vignette et overflow */
.extension_Products .vignette .product {
   overflow: visible;
}

/* cross selling très vieux sites */
@media (min-width: 1024px) {
	.extension_Products .modal-body {
		height: auto !important;
		min-height: 0 !important;
	}
}
@media (min-width: 320px) {
	.modal-content {
		min-height: 0;
	}
	.modal-body {
		height: auto;
		min-height: 0 !important;
	}
}

/* bouton minus et plus */
.minus.btn,
.plus.btn {
   white-space: nowrap;
   min-width: 2.2rem;
}

/* ==========================================================================
   Module cart
   ========================================================================== */

/* bug bordures chrome */
.left.product-header.space-wrap.smallDelete.productLabelWidth {
    left: 0;
    text-indent: -10px;
}

/* ==========================================================================
   Module séances
   ========================================================================== */

/* évite le débordement du focus sur le calendrier et ajustement des tailles de dates et prix */
@media (min-width: 992px) {
	div[data-sessions-container="external"] ~ form .calendar,
	div[data-sessions-container="external"] ~ form #calendar,
	.sessions-external {
		float: left;
		width: 50% !important;
	}
   div[data-sessions-container="external"] ~ form .calendar .day {
      padding: 0 7px;
   }
	div[data-sessions-container="external"] ~ form .calendar .day .price {
		font-size: 0.8rem;
	}
}
@media (min-width: 1200px) {
   div[data-sessions-container="external"] ~ form .calendar .day .dayDate,
   div[data-sessions-container="external"] ~ form .calendar .day .price {
       font-size: 1rem;
   }
}

/* ==========================================================================
   Date picker date d'anniversaire à l'inscription
   ========================================================================== */

.bootstrap-datetimepicker-widget table thead tr:first-child th,
.bootstrap-datetimepicker-widget table td span:not(.active),
.bootstrap-datetimepicker-widget table td span.year:not(.active),
.bootstrap-datetimepicker-widget table td span.month:not(.active),
.bootstrap-datetimepicker-widget table td.day:not(.active) {
    color: #333;
}
.bootstrap-datetimepicker-widget table td.day.new,
.bootstrap-datetimepicker-widget table td.day.old {
    color: rgb(192, 192, 192);
}

/* ==========================================================================
   Formulaire
   ========================================================================== */
   .newUser .formitem select {
      width: 100%;
   }

   @media(min-width: 768px){
      .newUser .formitem select {
          width: auto;
      }
  }

  .extension_Form .left .multi-date, 
  .extension_Form .left .row-fluid  {
     display: block;
  }

/* ==========================================================================
   Message 'Ajouté au panier'
   ========================================================================== */

@media (min-width: 981px) {
    .addButton span {
        margin-bottom: 10px;
        margin-top: 10px;
    }
}

/* ==========================================================================
   Regroupements de produits
   ========================================================================== */

.grouping-info {
   padding-top: 1.5rem;
}

.grouping-label {
   font-weight: bold;
}

// à supprimer si correctif GAT-7419 complètement déployé
.grouping-image[src="/SAPI/CMS0000000/Image/"] {
   display: none;
}


/* ==========================================================================
   Adhésions
   ========================================================================== */

// fiche profil
.userFoundPass-wrapper .userFound-infos {
	top: 0;
	left: 154px;
}

/* fin CSS dette technique */
