/* iOS6, iOS7, iOS8, IE10 flex compatibility for GAT3 modules */


/* optimisation Safari 5+ et iOS du menu bottom : activer l'auto-prefix sur le compilateur */

@media (min-width: 769px) {
    .bottom .tunnel .tunnelWrapper {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
    }
    .bottom .tunnel .previous {
        flex: auto;
    }
}


/* optimisation Safari 5+ et iOS du calendrier et des sessions */
article[data-action="Default"].extension_Calendar .monthSwitcher,
article[data-action="Calendar"].extension_Calendar .monthSwitcher,
article[data-action="CalendarsAccordion"].extension_Calendar .monthSwitcher {
    display: flex;
    justify-content: space-between;
}
article[data-action="Default"].extension_Calendar .dayName,
article[data-action="Calendar"].extension_Calendar .dayName,
article[data-action="CalendarsAccordion"].extension_Calendar .dayName {
    flex: 7;
}
article[data-action="Default"].extension_Calendar .dayNames,
article[data-action="Calendar"].extension_Calendar .dayNames,
article[data-action="CalendarsAccordion"].extension_Calendar .dayNames,
article[data-action="Default"].extension_Calendar .week,
article[data-action="Calendar"].extension_Calendar .week,
article[data-action="CalendarsAccordion"].extension_Calendar .week {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
}
article[data-action="Default"].extension_Calendar .day,
article[data-action="Calendar"].extension_Calendar .day,
article[data-action="CalendarsAccordion"].extension_Calendar .day {
    flex: 7;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
}
article[data-action="Default"].extension_Calendar
    .sessions-day
    .sessionFlexContainer,
article[data-action="Calendar"].extension_Calendar
    .sessions-day
    .sessionFlexContainer,
article[data-action="CalendarsAccordion"].extension_Calendar
    .sessions-day
    .sessionFlexContainer {
    flex-direction: column;
    justify-content: flex-end !important;
}
article[data-action="SessionsGrid"].extension_Calendar .sessionFlexContainer {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
}
#calendar .session,
.sessions-external .session {
    flex-basis: 19.7%;
}
#calendar .sessionsgrid .sessionFlexContainer,
.sessions-external .sessionsgrid .sessionFlexContainer {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    flex-wrap: wrap;
}
#calendar .sessions .session,
.sessions-external .sessions .session {
    display: flex;
    justify-content: space-between;
}

@media(max-width:767px){
	#productsModal .modal-body{
		overflow: scroll;
	}
}
