/* RGPD / GDPR patch de mise en forme du formulaire d'inscription */

.extension_Form .newUser .control-group input[type="radio"] {
    width: auto;
    display: inline-block!important;
}
.extension_Form .newUser .control-group input[type="radio"] + label {
    margin-top: .5rem;
    margin-bottom: .5rem;
}
.newUser fieldset {
    margin-bottom: 1rem;
}
.newUser fieldset .control > label {
    /*font-weight: bold !important;*/
}
.extension_Form .left .group-radio legend,
.extension_Form .top .group-radio legend {
    font-size: 14px;
}
.extension_Form .left .group-radio .control-group ,
.extension_Form .top .group-radio .control-group {
    display: flex;
    align-items: center;
}
.extension_Form .left .group-radio .control-group label ,
.extension_Form .top .group-radio .control-group label {
    margin-left: 10px;
}
.extension_Form .left .group-radio .control-group input[type="radio"],
.extension_Form .top .group-radio .control-group input[type="radio"] {
    margin-left: 5px;
}
.extension_Form .top label, 
.extension_Form .top input, 
.extension_Form .top .control div{

}
.extension_Form legend{
    font-size: 0.875rem;
}
@media (max-width: 767px) {
    .extension_Form .left .group-radio .control-group ,
    .extension_Form .top .group-radio .control-group {
        align-items: baseline;
    }
    .extension_Form .left .group-radio .control-group input[type="radio"] ,
    .extension_Form .top .group-radio .control-group input[type="radio"] {
        width: auto!important;
    }
    .extension_Form .left .group-radio .control-group label ,
    .extension_Form .top .group-radio .control-group label {
        margin-left: 10px!important;
    }
}