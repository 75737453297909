$footer-menu:  "inline";

/*==========================================================================================*/
/*================                   FOOTER MENU                                   ================*/
/*==========================================================================================*/

footer ul.nav li {
    display: inline-block !important;
    width: auto !important;
    
}
footer ul.nav li a {
    padding: .5em 1em;   
}

